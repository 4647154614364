var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      directives: [
        {
          name: "show",
          rawName: "v-show",
          value: _vm.pageListShow,
          expression: "pageListShow",
        },
      ],
      staticClass: "page-list",
    },
    [
      _c("div", { staticClass: "pagetion" }, [
        _vm.showOptions.leftOptions[0].checked
          ? _c(
              "div",
              { staticClass: "pagetion-content" },
              _vm._l(_vm.pageListData, function (item, idx) {
                return _c(
                  "div",
                  { key: item.id, staticClass: "pagetion-content-show" },
                  [
                    _c(
                      "div",
                      {
                        class:
                          _vm.selectIdx == idx
                            ? "pagetion-item-select pagetion-item"
                            : "pagetion-item",
                        on: {
                          click: function ($event) {
                            return _vm.changeSelectIdx(idx)
                          },
                        },
                      },
                      [_vm._v("\n          " + _vm._s(idx + 1) + "\n        ")]
                    ),
                    idx < _vm.pageListData.length - 1
                      ? _c("div", { staticClass: "pagetion-item-label" }, [
                          _vm._v("●"),
                        ])
                      : _vm._e(),
                  ]
                )
              }),
              0
            )
          : _vm._e(),
        _vm.showOptions.leftOptions.length > 1 &&
        _vm.showOptions.leftOptions[1].checked
          ? _c(
              "div",
              { staticClass: "pagetion-content" },
              _vm._l(_vm.jpgListData, function (item, idx) {
                return _c(
                  "div",
                  { key: item.id, staticClass: "pagetion-content-show" },
                  [
                    _c(
                      "div",
                      {
                        class:
                          _vm.selectIdx == idx
                            ? "pagetion-item-select pagetion-item"
                            : "pagetion-item",
                        on: {
                          click: function ($event) {
                            return _vm.changeSelectIdx(idx)
                          },
                        },
                      },
                      [_vm._v("\n          " + _vm._s(idx + 1) + "\n        ")]
                    ),
                    idx < _vm.jpgListData.length - 1
                      ? _c("div", { staticClass: "pagetion-item-label" }, [
                          _vm._v("●"),
                        ])
                      : _vm._e(),
                  ]
                )
              }),
              0
            )
          : _vm._e(),
      ]),
      _c("div", { staticClass: "list-slider" }, [
        _vm.taskDetail.productionName
          ? _c(
              "div",
              { staticClass: "list-title" },
              [
                _c(
                  "a-tooltip",
                  [
                    _c("template", { slot: "title" }, [
                      _c("div", [
                        _vm._v(
                          _vm._s(
                            _vm.taskDetail.productSequenceNo +
                              "-" +
                              _vm.taskDetail.productionName
                          )
                        ),
                      ]),
                    ]),
                    _c("div", [
                      _vm._v(
                        _vm._s(
                          _vm.taskDetail.productSequenceNo +
                            "-" +
                            _vm.taskDetail.productionName
                        )
                      ),
                    ]),
                  ],
                  2
                ),
                _c(
                  "div",
                  { staticStyle: { "padding-top": "5px" } },
                  [
                    _c(
                      "a-select",
                      {
                        staticStyle: { width: "100%" },
                        attrs: {
                          "option-filter-prop": "label",
                          "show-search": "",
                          placeholder: "请选择章节",
                        },
                        on: { change: _vm.chapterChange },
                        model: {
                          value: _vm.chapterId,
                          callback: function ($$v) {
                            _vm.chapterId = $$v
                          },
                          expression: "chapterId",
                        },
                      },
                      _vm._l(_vm.chapterList, function (chapter) {
                        return _c(
                          "a-select-option",
                          {
                            key: chapter.chapterId,
                            attrs: {
                              label: chapter.label,
                              value: chapter.chapterId,
                            },
                          },
                          [
                            _vm._v(
                              "\n            " +
                                _vm._s(chapter.label) +
                                "\n          "
                            ),
                          ]
                        )
                      }),
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            )
          : _vm._e(),
        _c(
          "div",
          [
            _c("a-slider", {
              attrs: { "default-value": 100, max: 100, min: 10 },
              on: { change: _vm.handledChange },
            }),
          ],
          1
        ),
      ]),
      _vm.showOptions.leftOptions[0].checked
        ? _c(
            "div",
            { staticClass: "list-content", on: { scroll: _vm.pageListScroll } },
            _vm._l(_vm.pageListData, function (item, idx) {
              return _c(
                "div",
                {
                  key: item.id,
                  class:
                    _vm.selectIdx == idx
                      ? "page-item-select page-item"
                      : _vm.notBlurIndex == idx || _vm.notBlurIndex + 1 == idx
                      ? "page-item"
                      : "page-item blur-page-item",
                },
                [
                  _c("img", {
                    ref: item.id + "pageImg",
                    refInFor: true,
                    style: "width:" + _vm.sliderValue + "%;height:auto",
                    attrs: { src: item.filePath },
                    on: { click: (e) => _vm.changeShowScroll(e, idx) },
                  }),
                  _c(
                    "div",
                    {
                      staticStyle: {
                        "word-wrap": "break-word",
                        "white-space": "pre-line",
                      },
                    },
                    [_vm._v(_vm._s(item.fileName))]
                  ),
                ]
              )
            }),
            0
          )
        : _vm._e(),
      _vm.showOptions.leftOptions.length > 1 &&
      _vm.showOptions.leftOptions[1].checked
        ? _c(
            "div",
            { staticClass: "list-content", on: { scroll: _vm.pageListScroll } },
            _vm._l(_vm.jpgListData, function (item, idx) {
              return _c(
                "div",
                {
                  key: item.id,
                  class:
                    _vm.selectIdx == idx
                      ? "page-item-select page-item"
                      : _vm.notBlurIndex == idx || _vm.notBlurIndex + 1 == idx
                      ? "page-item"
                      : "page-item blur-page-item",
                },
                [
                  _c("img", {
                    ref: item.id + "pageImg",
                    refInFor: true,
                    style: "width:" + _vm.sliderValue + "%;height:auto;",
                    attrs: { src: item.filePath },
                    on: { click: (e) => _vm.changeShowScroll(e, idx) },
                  }),
                  _c(
                    "div",
                    {
                      staticStyle: {
                        "word-wrap": "break-word",
                        "white-space": "pre-line",
                      },
                    },
                    [_vm._v(_vm._s(item.fileName))]
                  ),
                ]
              )
            }),
            0
          )
        : _vm._e(),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }